import React from 'react';
import Countdown from "react-countdown";
// import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
// import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
// import { ReactComponent as IconGithub } from './assets/icons/github.svg';
import "./App.css";



class App extends React.Component {
  render = () => {
      // Custom renderer function to display the countdown
      const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span>Countdown completed!</span>;
        } else {
          return (
            <div className="countdown-container">
              <div className="countdown-item">
                <span className="countdown-number">{days}</span>
                <span className="countdown-label">days</span>
              </div>
              <div className="countdown-item">
                <span className="countdown-number">{hours}</span>
                <span className="countdown-label">hours</span>
              </div>
              <div className="countdown-item">
                <span className="countdown-number">{minutes}</span>
                <span className="countdown-label">minutes</span>
              </div>
              <div className="countdown-item">
                <span className="countdown-number">{seconds}</span>
                <span className="countdown-label">seconds</span>
              </div>
            </div>
          );
        }
      };
    return (
      <div className="card">
        <div className="header">
          {/* <div className="logo">
            <a href=".">LOGO</a>
          </div>
          <div className="social">
            <a href="https://facebook.com" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://github.com/arkn98/coming-soon" title="GitHub" target="_blank" rel="noopener noreferrer">
              <IconGithub className="icon" />
            </a>
          </div> */}
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Welcome to Tissan and Ahranei's Wedding RSVP</h1>
          </div>
          <div className="title-holder">
            <Countdown date='2024-11-01T00:00:00' renderer={renderer} />
            </div>
          
          <div className="title-holder">
            <p>Website coming soon, please check back to know more.</p>
          </div>
          <a href="mailto:info.webflame@gmail.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span><a className="underlined" href="https://webflame.co.uk" target="_blank" rel="noopener noreferrer">Created by WebFlame</a></span>
        </div>
      </div>
    );
  }
}

export default App;